<div class="actfix-container">
    <p class="title">Listado de facturación telefónica</p>
    <div class="actfix-container-button">
        <div class="actfix-group">
            <input type="text" class="form-control" placeholder="Filtrar" (keyup)="filter($event)">
            <mat-icon class="blue-color">search</mat-icon>
            <button mat-mini-fab class="blue" matTooltip="Exportar" matTooltipPosition="above" matTooltipClass="tooltip"
                (click)="download()">
                <mat-icon>cloud_download</mat-icon>
            </button>
        </div>
    </div>
        <div *ngIf="loading" class="loading-overlay">
            <div class="spinner"></div>
        </div>
    <table #table class="actfix-table" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="Seleccion">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    (change)="toggleSelectAll($event.checked)"
                    [checked]="areAllSelected()"
                ></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let item">
                <mat-checkbox [(ngModel)]="item.selected"></mat-checkbox>
            </td>
        </ng-container> 
        <ng-container matColumnDef="SerialId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SerialId</th>
            <td mat-cell *matCellDef="let item">{{item.serialId}}</td>
        </ng-container>
        <ng-container matColumnDef="CodigoSap">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Código SAP</th>
            <td mat-cell *matCellDef="let item">{{item.codigoSap}}</td>
        </ng-container>
        <ng-container matColumnDef="Modelo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Modelo</th>
            <td mat-cell *matCellDef="let item">{{item.modelo}}</td>
        </ng-container>
        <ng-container matColumnDef="Serial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial</th>
            <td mat-cell *matCellDef="let item">{{item.serial}}</td>
        </ng-container>
        <ng-container matColumnDef="G0">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Precio G0</th>
            <td mat-cell *matCellDef="let item">{{item.g0}}</td>
        </ng-container>
        <ng-container matColumnDef="G1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Precio G1</th>
            <td mat-cell *matCellDef="let item">{{item.g1}}</td>
        </ng-container>
        <ng-container matColumnDef="G2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Precio G2</th>
            <td mat-cell *matCellDef="let item">{{item.g2}}</td>
        </ng-container>
        <ng-container matColumnDef="G3">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Precio G3</th>
            <td mat-cell *matCellDef="let item">{{item.g3}}</td>
        </ng-container>
        <ng-container matColumnDef="G4">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Precio G4</th>
            <td mat-cell *matCellDef="let item">{{item.g4}}</td>
        </ng-container>
        <ng-container matColumnDef="G5">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Precio G5</th>
            <td mat-cell *matCellDef="let item">{{item.g5}}</td>
        </ng-container>
        <ng-container matColumnDef="Tecnologia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tecnología</th>
            <td mat-cell *matCellDef="let item">{{item.tecnologia}}</td>
        </ng-container>
        <ng-container matColumnDef="FechaEmpaque">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de Empaque</th>
            <td mat-cell *matCellDef="let item">{{item.fecha}}</td>
        </ng-container>
        <ng-container matColumnDef="FechaFacturacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de Facturación</th>
            <td mat-cell *matCellDef="let item">{{item.fechaFacturacion}}</td>
        </ng-container>       
        <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let item">
                {{item.estado === true ? 'Facturado' : item.estado === false ? 'Sin facturar' : 'N/A'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Facturar">
            <th mat-header-cell *matHeaderCellDef>¿Facturar?</th>
            <td mat-cell *matCellDef="let item">
                <mat-checkbox 
                    [(ngModel)]="item.estado" 
                    [checked]="item.facturar" 
                    [disabled]="item.fechaFacturacion !== null">
                </mat-checkbox>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <div class="actfix-container-button">
        <div class="actfix-group">
            <button mat-stroked-button color="primary" (click)="billSelected()">Facturar Seleccionados</button>
        </div>
        <div class="actfix-group">
            <button class="actfix-butonsearch" (click)="markAll(true)">Marcar todo 'Facturado'</button>
        </div>
        <div class="actfix-group">
            <button class="actfix-butonsearch" (click)="markAll(false)">Marcar todo 'Sin facturar'</button>
        </div>
        <div class="actfix-group">
            <button mat-stroked-button color="warn" (click)="removeSelected()">Remover Seleccionados</button>
        </div>
    </div>
    <div *ngIf="loading" class="report-container-loader">
        <div class="report-loader"></div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>