import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmationComponent } from "src/app/shared/components/confirmation/confirmation.component";
import { BillingTelefonicaService } from "src/app/appBilling/Services/billingTelefonica.service";
import { BillingTelefonicaModel } from "../../Models/BillingTelefonica.model";

@Component({
    selector: "app-billingTelefonicaList",
    templateUrl: "./billingTelefonicaList.component.html",
    styleUrls: ["./billingTelefonicaList.component.css"],
})
export class BillingTelefonicaListComponent implements OnInit {
    loading: boolean;
    columns: string[];
    id: number;
    dataSource = new MatTableDataSource<any>();
    @ViewChild(MatTable) table: MatTable<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Inject(MAT_DIALOG_DATA) public data: any;
    @ViewChild(MatSort) sort: MatSort;
    unibytes: Uint8Array = null;
    form: any;
    constructor(
        private billingTelefonicaS: BillingTelefonicaService,
        private dialog: MatDialog,
        private alertS: AlertService
    ) {
        this.loading = false;
        this.columns = [
            "Seleccion",
            "SerialId",
            "CodigoSap",
            "Modelo",
            "Serial",
            "G0",
            "G1",
            "G2",
            "G3",
            "G4",
            "G5",
            "Tecnologia",
            "FechaEmpaque",
            "FechaFacturacion",
            "Estado",
            "Facturar",
        ];        
        this.dataSource = new MatTableDataSource([]);
    }

    ngOnInit(): void {
        this.list();
    }

    list(): void {
        this.loading = true; // Activa la bandera de carga
        this.billingTelefonicaS.list().subscribe(
            (res) => {
                this.loading = false; // Desactiva la bandera de carga
                if (res.message === "OK") {
                    this.dataSource = new MatTableDataSource<any>(res.object);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                } else {
                    this.alertS.open(res.message, "error");
                }
            },
            (err) => {
                this.loading = false; // Desactiva la bandera de carga
                this.alertS.open(err.message, "error");
            }
        );
    }

    filter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    create(selectedItems: BillingTelefonicaModel[]): void {
        const isSingle = selectedItems.length === 1;
        const dialogMessage = isSingle
            ? "¿Desea facturar este equipo?"
            : "¿Desea facturar estos equipos?";
    
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: "400px",
            data: { message: dialogMessage },
        });
    
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.loading = true; // Activa la bandera de carga
                this.billingTelefonicaS.create(selectedItems).subscribe(
                    (res) => {
                        this.loading = false; // Desactiva la bandera de carga
                        if (res.message === "OK") {
                            this.alertS.open("Facturación realizada con éxito.", "success");
                            this.list();
                        } else {
                            this.alertS.open("Error al facturar los equipos.", "error");
                        }
                    },
                    (err) => {
                        this.loading = false; // Desactiva la bandera de carga
                        this.alertS.open("Error en la comunicación con el servidor.", "error");
                    }
                );
            }
        });
    }
    
    delete(selectedItems: BillingTelefonicaModel[]): void {
        const isSingle = selectedItems.length === 1;
        const dialogMessage = isSingle
            ? "¿Desea quitar este equipo para facturar?"
            : "¿Desea quitar estos equipos para facturar?";
    
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: "400px",
            data: { message: dialogMessage },
        });
    
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.loading = true; // Activa la bandera de carga
                this.billingTelefonicaS.delete(selectedItems).subscribe(
                    (res) => {
                        this.loading = false; // Desactiva la bandera de carga
                        if (res.message === "OK") {
                            if (res.object != 0) {
                                this.alertS.open("Equipos quitados con éxito.", "success");
                                this.list();
                            } else {
                                this.alertS.open("Error al quitar los equipos.", "error");
                            }
                        } else {
                            this.alertS.open("Error al quitar los equipos.", "error");
                        }
                    },
                    (err) => {
                        this.loading = false; // Desactiva la bandera de carga
                        this.alertS.open("Error en la comunicación con el servidor.", "error");
                    }
                );
            }
        });
    }  

    billSelected(): void {
        const selectedItems = this.dataSource.data.filter(
            (item) => item.selected && item.fechaFacturacion === null
        );
    
        if (selectedItems.length > 0) {
            this.create(selectedItems);
        } else {
            this.alertS.open(
                "No hay equipos seleccionados o están ya facturados.",
                "warning"
            );
        }
    }
    

    removeSelected(): void {
        const selectedItems = this.dataSource.data.filter(
            (item) => item.selected && item.fechaFacturacion !== null
        );
    
        if (selectedItems.length > 0) {
            this.delete(selectedItems);
        } else {
            this.alertS.open(
                "No hay equipos seleccionados o no tienen fecha de facturación.",
                "warning"
            );
        }
    }

    areAllSelected(): boolean {
        return this.dataSource.data.every((item) => item.selected);
    }
    
    toggleSelectAll(checked: boolean): void {
        this.dataSource.data.forEach((item) => (item.selected = checked));
    }

    markAll(isFactured: boolean) {
        this.dataSource.data.forEach((item) => {
            if (item.fechaFacturacion === null) {
                item.facturar = isFactured;
                item.estado = isFactured;
            }
        });
        this.dataSource._updateChangeSubscription();
    }    

    download() {
        let delimiter = "\\";
        let headers = "";
        let file = "";

        for (let i = 0; i < this.columns.length; i++) {
            headers = headers + this.columns[i];
            if (i < this.columns.length - 1) {
                headers = headers + delimiter;
            }
        }
        file = headers;
        for (let i = 0; i < this.dataSource.data.length; i++) {
            file = file + "\n";
            for (let j = 0; j < this.columns.length; j++) {
                file = file + this.dataSource.data[i][this.columns[j]];
                file = file + delimiter;
            }
        }
        let blob = new Blob(["\ufeff" + file], { type: "text/csv;charset=utf-8;" });
        let url = URL.createObjectURL(blob);
        let downloadLink = document.createElement("a");
        downloadLink.setAttribute("href", url);
        downloadLink.setAttribute("download", "Facturacion_Telefonica.csv");
        downloadLink.style.visibility = "hidden";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
}
