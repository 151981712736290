import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ResponseModel } from "src/app/shared/models/response.model";
import { BillingTelefonicaModel } from "../Models/BillingTelefonica.model";

@Injectable({
    providedIn: "root",
})
export class BillingTelefonicaService {
    private url: string;

    constructor(private http: HttpClient) {
        this.url = environment.api;
    }

    public create(
        BillingTelefonicaModel: BillingTelefonicaModel[]
    ): Observable<ResponseModel> {
        var headers = new HttpHeaders().set("content-Type", "application/json");

        return this.http.post<ResponseModel>(
            this.url + "BillingTelefonica/create",
            JSON.stringify(BillingTelefonicaModel),
            { headers: headers }
        );
    }

    public delete(
        BillingTelefonicaModel: BillingTelefonicaModel[]
    ): Observable<ResponseModel> {
        const headers = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.post<ResponseModel>(
            this.url + "BillingTelefonica/delete",
            JSON.stringify(BillingTelefonicaModel),
            { headers: headers }
        );
    }

    public list(): Observable<ResponseModel> {
        var headers = new HttpHeaders().set("content-Type", "application/json");
        return this.http.get<ResponseModel>(this.url + "BillingTelefonica/list", {
            headers: headers,
        });
    }
}
