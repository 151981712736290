import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AlertService } from "src/app/shared/services/alert.service";
import { ActiveFixedEntity } from "../../entities/activeFixed.entity";
import { ActiveFixedAssigmentEntity } from "../../entities/activeFixedAssigment.entity";
import { ActiveFixedAssigmentFeatur } from "../../models/ActiveFixedAssigFeatur.model";
import { ActiveFixedAssigmentFile } from "../../models/ActiveFixedAssigmentFile.model";
import { ActiveFixedAssigmentService } from "../../services/activeFixedAssigment.service";

@Component({
    selector: "modal-actProdDetailAll",
    templateUrl: "actProdDetailAll.modal.html",
    styleUrls: ["./actProdDetailAll.modal.css"],
})
export class ActDetailAllModal {
    activeFixedAssigmentFeatur: ActiveFixedAssigmentFeatur;
    loading: boolean;
    filetList: ActiveFixedAssigmentFile[];
    activeFixedAsigE: ActiveFixedAssigmentEntity;
    actFixedEntity: ActiveFixedEntity[];

    form: FormGroup;

    constructor(
        private activeFixedS: ActiveFixedAssigmentService,
        private alertS: AlertService,
        public dialogRef: MatDialogRef<ActDetailAllModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder
    ) {
        this.loading = false;
        this.actFixedEntity = [];
        this.filetList = [];

        this.form = this.fb.group({ 
            mouse: [{ value: false, disabled: true }],
            bag: [{ value: false, disabled: true }],
            adapter: [{ value: false, disabled: true }],
            guaya: [{ value: false, disabled: true }],
            keyboard: [{ value: false, disabled: true }]
        });
    }

    ngOnInit(): void {
        this.loading = true;
        this.activeFixedS.assigFeaturAll(this.data.id).subscribe(
            (res) => {
                if (res.message === "OK") {
                    this.loading = false;
                    this.activeFixedAssigmentFeatur = res.object;
    
                    this.activeFixedS.findById(this.data.id).subscribe(
                        (res) => {
                            if (res.message === "OK") {
                                this.activeFixedAsigE = res.object;
                                this.form.patchValue({
                                    mouse: this.activeFixedAsigE.mouse,
                                    bag: this.activeFixedAsigE.bag,
                                    adapter: this.activeFixedAsigE.adapter,
                                    guaya: this.activeFixedAsigE.guaya,
                                    keyboard: this.activeFixedAsigE.keyboard
                                });
                            } else {
                                this.alertS.open(res.message, "error");
                            }
                        },
                        (err) => {
                            this.alertS.open(err.message, "error");
                        }
                    );
    
                    this.activeFixedS
                        .listFile(this.data.identification, this.data.creationDate)
                        .subscribe(
                            (resL) => {
                                if (resL.message === "OK") {
                                    this.filetList = resL.object;
                                } else {
                                    this.alertS.open(resL.message, "error");
                                }
                            },
                            (err) => {
                                this.alertS.open(err.message, "error");
                            }
                        );
                } else {
                    this.alertS.open(res.message, "error");
                }
            },
            (err) => {
                this.alertS.open(err.message, "error");
            }
        );
    }
    

    downloadFile(file: ActiveFixedAssigmentFile) {
        var downloadLink = document.createElement("a");
        if (file.type === "imagen") {
            downloadLink.setAttribute("href", "data:image/png;base64," + file.file);
        } else {
            var binary = window.atob(file.file);
            var binaryLength = binary.length;
            var bytes = new Uint8Array(binaryLength);
            for (var i = 0; i < binaryLength; i++) {
                var ascii = binary.charCodeAt(i);
                bytes[i] = ascii;
            }
            var blob = new Blob([bytes], { type: "application/" + file.type });
            downloadLink.href = window.URL.createObjectURL(blob);
        }
        downloadLink.setAttribute("download", file.name + "." + file.type);
        downloadLink.style.visibility = "hidden";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
}
