import { Routes, RouterModule } from '@angular/router';
import { Component, NgModule } from '@angular/core';
import { AuthGuardService } from '../shared/services/authGuard.service';
import { BillingPrincipalComponent } from './components/principal/billing.component';
import { BillingTelefonicaListComponent } from './Components/telefonica/billingTelefonicaList.component';

const routes: Routes = [
  {
    path: 'billing', component: BillingPrincipalComponent, canActivate: [AuthGuardService],
    children: [
      { path: 'telefonica/list', component: BillingTelefonicaListComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppBillingRoutingModule { }
