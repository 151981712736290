import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppBillingRoutingModule } from './appBillingRouting.module';
import { MaterialModule } from '../material.module';
import { BillingPrincipalComponent } from './components/principal/billing.component';
import { WebcamModule } from 'ngx-webcam';
import { BillingTelefonicaListComponent } from './Components/telefonica/billingTelefonicaList.component';




@NgModule({
  declarations: [BillingPrincipalComponent, BillingTelefonicaListComponent],
  imports: [
    CommonModule,
    AppBillingRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    WebcamModule,
  ]
})
export class AppBillingModule { }
